import {TweenMax} from 'gsap';
import {gsap} from 'gsap/gsap-core';
import React, {useContext, useEffect, useRef} from 'react';
import {NavigationContext} from './NavigationContextProvider';
import {NavigationItem} from './NavigationItem';


const SectionNavigation = (props) =>
      {
        const navItemRef = useRef();
        const navPrevRef = useRef();
        const navNextRef = useRef();

        const {onPrevClick, onNextClick} = useContext(NavigationContext);


        useEffect(() =>
        {
          if(navItemRef.current)
          {
            if(props.isCurrentSection === true)
            {
              if(props.prev)
              {
                TweenMax.fromTo(navPrevRef.current.container.current, .3, {y: 100, alpha: 0}, {y: 0, alpha: 1, delay: .1});
              }
              gsap.killTweensOf([navItemRef.current.caption.current, navItemRef.current.icon.current]);
              TweenMax.fromTo(navItemRef.current.icon.current, .3, {scale: 0, alpha: 1}, {scale: 1, alpha: 1});
              TweenMax.fromTo(navItemRef.current.caption.current, .3, {width: 0, alpha: 0}, {width: 'auto', alpha: 1, delay: .3});

              if(props.next)
              {
                TweenMax.fromTo(navNextRef.current.container.current, .3, {y: -100, alpha: 0}, {y: 0, alpha: 1, delay: .1});
              }

            }
            else
            {
              if(props.prev)
              {
                gsap.killTweensOf([navPrevRef.current.container.current]);
                TweenMax.to(navPrevRef.current.container.current, .1, {alpha: 0});
              }

              gsap.killTweensOf([navItemRef.current.caption.current, navItemRef.current.icon.current]);
              TweenMax.to(navItemRef.current.caption.current, .1, {alpha: 0});
              TweenMax.to(navItemRef.current.icon.current, .2, {alpha: 0});

              if(props.next)
              {
                gsap.killTweensOf([navNextRef.current.container.current]);
                TweenMax.to(navNextRef.current.container.current, .1, {alpha: 0});
              }
            }
          }

        }, [navPrevRef, navNextRef, navItemRef, props.prev, props.next, props.isCurrentSection]);


        const onPrevItemClicked = () =>
        {
          console.log('onPrevItemClicked', props.sectionID);
          onPrevClick(props.sectionID);
        };
        const onNextItemClicked = () =>
        {
          console.log('onNextItemClicked', props.sectionID);
          onNextClick(props.sectionID);
        };


        return (
          <>
            {/*<div className={'navCont'}>*/}
            <div className={'navigationSide'} style={{position: props.fixed ? 'fixed' : 'absolute'}}>
              {props.prev ? <NavigationItem title={'prev'} className={'sub'} onClick={onPrevItemClicked} ref={navPrevRef} /> : null}
              <NavigationItem doHover={false} ref={navItemRef} className={'main'} title={props.title} onClick={() => {}} />
              {props.next ? <NavigationItem title={'next'} className={'sub'} onClick={onNextItemClicked} ref={navNextRef} /> : null}
              <div className={'line'} />
            </div>
            {/*</div>*/}
          </>
        );
      }
;

export {SectionNavigation};