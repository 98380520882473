import React, {useContext, useEffect, useRef, useState} from 'react';
import {NavigationContext} from './NavigationContextProvider';
import {gsap} from 'gsap/gsap-core';


const HeroImage = (props) =>
{
  const {scrollY, windowSize, inViewDelta} = useContext(NavigationContext);

  const dotRef = useRef();
  const [dotStyle, setDotStyle] = useState({position: 'fixed'});


  //  useEffect(() =>
  //  {
  //    if(scrollY >= 200)
  //    {
  //      console.log('scroll - relative');
  //      setDotStyle((style) => ({...style, position: 'absolute'}));
  //    }
  //    else
  //    {
  //
  //      console.log('scroll - absolut');
  //      setDotStyle((style) => ({...style, position: 'sticky'}));
  //    }
  //
  //
  //  }, [scrollY]);


  useEffect(() =>
  {
    const minScroll = window.innerHeight * (1 - .65);
    if(scrollY >= minScroll)
    {
      setDotStyle((style) => ({...style, position: 'absolute', top: '100vh'}));
    }
    else
    {
      setDotStyle((style) => ({...style, position: 'fixed', top: '65vh'}));
    }


  }, [scrollY]);


  const onDotClick = () =>
  {
    const minScroll = window.innerHeight * (1 - .65);
    gsap.to(window, {scrollTo: minScroll, duration: .3});
  };


  return (
    <header>
      <div className={'headerCont'}>
        <h1>Some Hero Image</h1>
      </div>
      <div className={'startTour'} style={dotStyle} ref={dotRef} onClick={onDotClick}>
        <div className={'dot'} />
      </div>

    </header>
  );
};

export {HeroImage};