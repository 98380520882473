import React from 'react';
import useViewportCheck from './hooks/useViewportCheck';


const NavigationContext = React.createContext({});

const NavigationContextProvider = (props) =>
{
  const inViewDelta = .35;
  const {windowSize, addSection, scrollY, sections, onPrevClick, onNextClick, onGotoSection, lastInViewPort} = useViewportCheck(inViewDelta);


  return (
    <NavigationContext.Provider value={{
      windowSize,
      addSection,
      inViewDelta,
      scrollY,
      sections,
      onPrevClick,
      onNextClick,
      onGotoSection,
      lastInViewPort
    }}
    >
      {props.children}
    </NavigationContext.Provider>
  );
};

export {NavigationContextProvider, NavigationContext};