import {gsap} from 'gsap/gsap-core';
import PropTypes from 'prop-types';
import React, {useImperativeHandle, useRef} from 'react';


const NavigationItem = React.forwardRef((props, ref) =>
{
  const captionRef = useRef();
  const iconRef = useRef();
  const contRef = useRef();
  const fillRef = useRef();


  useImperativeHandle(ref, () => ({
    get caption()
    {
      return captionRef;
    },
    get icon()
    {
      return iconRef;
    },
    get container()
    {
      return contRef;
    }
  }));

  const onMouseEnter = () =>
  {
    if(props.doHover)
    {
      gsap.to(iconRef.current, {duration: .3, backgroundColor: '#0f5ef2', borderColor: '#ffffff'});
      gsap.to(fillRef.current, {duration: .3, width: '100%'});
    }
  };

  const onMouseLeave = () =>
  {
    if(props.doHover)
    {
      gsap.to(iconRef.current, {duration: .3, backgroundColor: '#202020', borderColor: '#0f5ef2'});
      gsap.to(fillRef.current, {duration: .3, width: '0%'});
    }
  };

  return (
    <>
      <div className={'navigationItem ' + props.className} ref={contRef}
           onClick={() => {props.onClick();}}
           onMouseEnter={onMouseEnter}
           onMouseLeave={onMouseLeave}
      >
        <div className={'icon'} ref={iconRef} />
        <div className={'caption'} ref={captionRef}>
          <div className={'fill'} ref={fillRef}></div>
          <div className={'text'}> {props.title}</div>
        </div>
      </div>
    </>
  );
});


NavigationItem.propTypes = {
  onClick  : PropTypes.func,
  className: PropTypes.string,
  doHover  : PropTypes.bool
};

NavigationItem.defaultProps = {
  doHover: true
};


export {NavigationItem};