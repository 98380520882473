import PropTypes from 'prop-types';
import React, {useContext} from 'react';
import {NavigationContext} from './NavigationContextProvider';
import {NavigationItem} from './NavigationItem';


const IntroSection = (props) =>
{

  const {onGotoSection} = useContext(NavigationContext);

  return (
    <div className={'sectionCont'}
         style={{
           backgroundColor: props.backgroundColor,
           height         : props.dummyHeight
         }}
    >
      <div className={'content'}>
        <h1>{props.title}</h1>
      </div>
      <div className={'navigationSide'}>
        <NavigationItem doHover={true} className={'main'} title={'Section One'} onClick={() => {onGotoSection(0);}} />
        <NavigationItem doHover={true} className={'main'} title={'Section Two'} onClick={() => {onGotoSection(1);}} />
        <NavigationItem doHover={true} className={'main'} title={'Section Three'} onClick={() => {onGotoSection(2);}} />
        <NavigationItem doHover={true} className={'main'} title={'Section Four'} onClick={() => {onGotoSection(3);}} />
        <div className={'line'} />
      </div>
    </div>
  );
};

IntroSection.propTypes = {
  title          : PropTypes.string,
  id             : PropTypes.number,
  backgroundColor: PropTypes.string,
  dummyHeight    : PropTypes.number
};

IntroSection.defaultProps = {
  title          : 'Your title here!',
  dummyHeight    : 600,
  backgroundColor: '#000000'
};


export {IntroSection};