import PropTypes from 'prop-types';
import React, {useContext, useEffect, useRef, useState} from 'react';
import {NavigationContext} from './NavigationContextProvider';
import {SectionNavigation} from './SectionNavigation';


const Section = (props) =>
{
  const sectionEl = useRef(null);

  const [sectionObj, setSectionObj] = useState(null);
  const [inViewPort, setInViewPort] = useState(false);
  const [isCurrentSection, setIsCurrentSection] = useState(false);
  const {windowSize, addSection, scrollY, sections, lastInViewPort} = useContext(NavigationContext);

  const [fixed, setFixed] = useState(false);


  useEffect(() =>
  {
    if(!sectionObj)
    {
      const section = addSection({sectionEl: sectionEl, id: props.id, inViewPort: false});
    }
  }, [addSection, sectionObj, props.id]);

  useEffect(() =>
  {
    if(sectionObj && sectionObj.inViewPort)
    {
      setInViewPort(true);
    }
    else
    {
      setInViewPort(false);
    }
  }, [scrollY, sectionObj, setInViewPort]);


  useEffect(() =>
  {
    if(sections.length > 0)
    {
      let section = sections[props.id];
      setInViewPort(section.inViewPort);
      setSectionObj(section);
    }


  }, [setInViewPort, sections, props.id, sectionObj]);


  useEffect(() =>
  {
    if(sectionEl.current)
    {
      const el = sectionEl.current;
      if(el.offsetTop - scrollY <= 0)
      {
        setFixed(true);
      }
      else
      {
        setFixed(false);
      }
    }


  }, [props.id, scrollY, sectionEl, setFixed]);

  useEffect(() =>
  {
    if(props.id === lastInViewPort)
    {
      setIsCurrentSection(true);
    }
    else
    {
      setIsCurrentSection(false);
    }


  }, [lastInViewPort, props.id]);

  return (
    <div className={'sectionCont'} ref={sectionEl}
         style={{
           backgroundColor: props.backgroundColor,
           height         : props.dummyHeight
         }}
    >
      <div className={'content'}>
        <h1>{props.title}</h1>
        <pre>SectionID: {props.id}</pre>
        <pre>{windowSize.height}</pre>
        <pre>{inViewPort.toString()}</pre>
      </div>
      <SectionNavigation isCurrentSection={isCurrentSection} fixed={fixed} title={props.title} inViewPort={inViewPort} next={props.next} prev={props.prev} sectionID={props.id} />
    </div>
  );
};

Section.propTypes = {
  title          : PropTypes.string,
  id             : PropTypes.number,
  backgroundColor: PropTypes.string,
  dummyHeight    : PropTypes.number,
  prev           : PropTypes.bool,
  next           : PropTypes.bool
};

Section.defaultProps = {
  title      : 'Your title here!',
  dummyHeight: 600,
  prev       : false,
  next       : false
};


export {Section};