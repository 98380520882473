import {TweenMax} from 'gsap';
import {useCallback, useEffect, useState} from 'react';


const useViewportCheck = (inViewDelta) =>
{
  const [windowSize, setWindowSize] = useState({width: window.innerWidth, height: window.innerHeight});
  const [scrollY, setScrollY] = useState();
  const [sections, setSections] = useState([]);
  const [lastInViewPort, setLastInViewPort] = useState(-1);


  //register sections
  const addSection = useCallback((section) =>
  {
    setSections((items) =>
    {
      let newSections = [...items];
      newSections[section.id] = section;


      return newSections;
    });

  }, [setSections]);


  const getSectionDimension = useCallback(() =>
  {
    console.log('getDimensions');

  }, []);

  const onGotoSection = useCallback((id) =>
  {
    const targetSection = sections[id];
    if(targetSection)
    {
      const offset = window.innerHeight * .1;
      TweenMax.to(window, .6, {scrollTo: targetSection.sectionEl.current.offsetTop});
    }
  }, [sections]);

  const onPrevClick = useCallback((id) =>
  {
    onGotoSection(id - 1);

  }, [onGotoSection]);


  const onNextClick = useCallback((id) =>
  {
    onGotoSection(id + 1);
  }, [onGotoSection]);


  //set window size on resize
  useEffect(() =>
  {
    const onResize = () =>
    {
      setWindowSize(() => ({width: window.innerWidth, height: window.innerHeight}));

    };
    window.addEventListener('resize', onResize);

    return () =>
    {
      window.removeEventListener('resize', onResize);
    };
  }, []);


  useEffect(() =>
  {
    const onScroll = () =>
    {
      setScrollY(window.scrollY);
    };

    document.addEventListener('scroll', onScroll);
  }, []);


  useEffect(() =>
  {

    const delta = windowSize.height * inViewDelta;
    const winTop = 0 + scrollY;
    const winBottom = windowSize.height + scrollY;

    const inViewBottom = winBottom - delta;
    const newSections = [...sections];

    //    console.log('scroll update', 'winTop', winTop, 'winBottom', winBottom);

    let lastInViewPort = -1;

    for (let i = 0; i < sections.length; i++)
    {
      const section = sections[i];


      const curDomEl = section.sectionEl.current;
      if(curDomEl)
      {
        const elOffsetTop = curDomEl.offsetTop;
        if(elOffsetTop < inViewBottom)
        {
          newSections[i].inViewPort = true;
          lastInViewPort = i;

        }
        else
        {
          newSections[i].inViewPort = false;
        }
      }
    }

    console.log('lastInViewport', lastInViewPort);

    setLastInViewPort(lastInViewPort);

  }, [inViewDelta, scrollY, sections, setSections, windowSize.height, setLastInViewPort]);


  return {windowSize, addSection, scrollY, sections, onPrevClick, onNextClick, onGotoSection, lastInViewPort};
};

export default useViewportCheck;


