import {makeStyles} from '@material-ui/core/styles';
import React, {useContext} from 'react';
import {NavigationContext} from './NavigationContextProvider';


const useStyles = makeStyles((theme) => ({
  debugOut: {
    position       : 'fixed',
    top            : 0,
    left           : 0,
    backgroundColor: '#000000',
    opacity        : .95,
    color          : '#ffffff',
    padding        : 5,
    fontSize       : 10
  }
}));

const ViewHelperOutput = (props) =>
{
  const classes = useStyles();
  const {windowSize, scrollY, lastInViewPort} = useContext(NavigationContext);

  return (
    <div className={classes.debugOut}>
      <pre>
        width:{windowSize.width}<br />
        height:{windowSize.height}<br />
        scrollY:{scrollY}<br />
        currentSection:{lastInViewPort}
      </pre>

    </div>
  );
};

export {ViewHelperOutput};