import React, {useContext} from 'react';
import {NavigationContext} from '../NavigationContextProvider';


const InViewLine = (props) =>
{

  const {inViewDelta, windowSize} = useContext(NavigationContext);


  const linePos = windowSize.height - (windowSize.height * inViewDelta);


  return (
    <>
      <div className={'inViewLine'} style={{top: linePos}} />
    </>
  );
};

export {InViewLine};