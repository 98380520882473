import {gsap} from 'gsap';
import {ScrollToPlugin} from 'gsap/ScrollToPlugin';
import './App.css';
import {InViewLine} from './comps/helper/InViewLine';
import {HeroImage} from './comps/HeroImage';
import {IntroSection} from './comps/IntroSection';
import {NavigationContextProvider} from './comps/NavigationContextProvider';
import {Section} from './comps/Section';
import {ViewHelperOutput} from './comps/ViewHelperOutput';

import './styling.scss';


gsap.registerPlugin(ScrollToPlugin);


function App()
{
  return (
    <NavigationContextProvider>
      <div className="App">
        <div className={'content'}>
          <HeroImage />
          <IntroSection title={'Intro Section'} />
          <Section title={'Section One'} id={0} backgroundColor={'#212121'} dummyHeight={900} next={true} />
          <Section title={'Section Two'} id={1} backgroundColor={'#000000'} dummyHeight={900} prev={true} next={true} />
          <Section title={'Section Three'} id={2} backgroundColor={'#212121'} dummyHeight={900} prev={true} next={true} />
          <Section title={'Section Four'} id={3} backgroundColor={'#000000'} dummyHeight={900} prev={true} />

        </div>
        <ViewHelperOutput />
        <InViewLine />
      </div>
    </NavigationContextProvider>
  );
}


export default App;
